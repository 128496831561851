.main {
  padding-top: 100px;
}
.photo {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 15px auto 0;
  border-radius: 50%;
  object-fit: cover;
  background: #f3f3f3;
}

.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
}
.footerInner {
  border-top: 2px solid #e4e4e4;
  padding: 30px 12px;
  background-color: #fff;
  z-index: 3;
}
.logoContainer {
  text-align: center;
  padding-bottom: 150px;
}
