.main {
  position: relative;
  padding-top: 80px;
  padding-bottom: 30px;
}
.docViewer {
  border: none;
  border-radius: 6px;
}
.signHereText {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgb(0 0 0 / 10%);
  border-radius: 100px;
  padding: 14px 10px;
  position: relative;
  right: -70px;
  width: 145px;
  z-index: 1;
  font-size: 16px;
  font-weight: 600;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.chevronLeftIcon {
  cursor: pointer;
  position: absolute;
  left: 15px;
  width: 30px;
  top: 25px;
}
.homeBtn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.btnSign {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  width: auto;
  align-items: center;
  cursor: pointer;

  background-color: #000;
  padding: 15px;
  z-index: 1;
}
.btnSign small {
  font-size: 10px;
}
.signBtn {
  z-index: 1;
  filter: brightness(0) invert(1);
  height: 25px;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
.plusCircle {
  height: 35px;
  cursor: pointer;
}
.addSignee {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 30px 15px;
  background-color: #fff;
}
.input {
  background: #f3f3f3;
  border-radius: 8px;
  border: none;
  padding: 15px;
  font-size: 16px;
  height: 150px;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.usersContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 0 10px 0;
  position: relative;
}
.user {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.initialsContainer {
  text-align: center;
  height: 32px;
  width: 32px;
  background-color: #000;
  border-radius: 50%;
  position: relative;
  text-transform: uppercase;
}

.initialsContainer p {
  color: #ffffff;
  margin-left: 0px;
  font-weight: bold;
  font-size: 15px;
}

.center {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 14px;
}
.userName {
  width: calc(100% - 60px);
  margin-left: 10px;
  margin-bottom: 0;
  text-align: left;
}

.header_top {
  position: fixed;
  width: 100%;
  z-index: 3;
  text-align: center;
  padding: 15px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.emptyContainer {
  padding: 40px;
  border: 1px dotted #000;
  border-radius: 16px;
  opacity: 0.5;
  text-align: center;
  height: 100px;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
  z-index: 3;
  border-top: 2px solid #e4e4e4;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
.animateUp {
  animation: animateUp 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}

.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
}
.signeeContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
}
.signee {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 8px;
  position: absolute;
  right: 20px;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
.homeBtn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.center {
  color: #8e8e8e;
  margin-left: 5px;
  font-weight: 800;
  font-size: 10px;
}
.fieldsScroller2 {
  height: calc(100vh - 440px);
  margin-left: -10px;
  width: calc(100% + 20px);
}
.scrollBox {
  height: 125px;
}
.userCheck input {
  right: 25px !important;
  text-align: left;
}

.userCheck label,
.labelMediumWeight label {
  font-weight: 500 !important;
  font-family: 'manrope-medium' !important;
}

.footerWhite {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: unset;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
  z-index: 91;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerInner {
  max-height: calc(100vh - 58px);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* padding: 30px 15px; */
  background-color: #fff;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.footerInnerScroll {
  max-height: calc(100vh - 58px);
  overflow-x: hidden;
}
.footerInnerShow {
  display: block;
}
.footerInnerHide {
  display: none !important;
}
.acknowledgementScroller {
  overflow-y: scroll;
  background-color: #fff;
  padding: 0 !important;
}
.acknowledgementPlaceholder {
  margin-left: 30px;
  margin-right: 30px;
}
.acknowledgementScroller::-webkit-scrollbar {
  width: 0;
}

.acknowledgementPopup {
  width: 50%;
  margin-left: 25% !important;
  position: fixed;
  left: 0;
  bottom: 0;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  border-radius: 10px 10px 0px 0px;
  border-top: 15px solid #fff;
  animation: slideAnimateUp 0.2s linear;
  transition: 0.2s ease-in-out;
  z-index: 200;
}
@keyframes slideAnimateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.customBreakpoint {
  display: block;
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .customBreakpoint {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 788px) {
  .customBreakpoint {
    display: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 395px) {
  .customBreakpoint {
    display: none;
  }
}

@media (max-width: 991px) {
  .acknowledgementPopup {
    width: 66.66666667%;
    margin-left: 16.66666667% !important;
  }
}
@media (max-width: 767px) {
  .acknowledgementPopup {
    width: 100%;
    margin-left: 0 !important;
  }
}
