.main {
  padding-top: 30px;
  padding-bottom: 80px;
  position: relative;
}
.profilePic {
  height: 250px;
  width: 250px;
  margin: 0 auto;
  border-radius: 50%;
}
.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
.whiteLogo {
  position: absolute;
  left: 0;
  right: -10px;
  margin: 0 auto;
  bottom: -40px;
  width: 100px;
}
.logoContainer {
  text-align: center;
  padding-bottom: 80px;
}
