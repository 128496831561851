.webcamContainer {
  position: relative;
  width: 100%;
  height: 0;
  /* padding-bottom: 100%; */
}
.webcamFlip {
  transform: rotateY(180deg);
}
.webcam {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 370px;
  width: 340px;
  border-radius: 25px;
  object-fit: cover;
  top: 50%;
  /* transform: translateY(-50%); */
}
.faceOutlineImg {
  transform: rotateY(180deg);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 175px;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  max-width: 300px;
}
.cameraBtns {
  position: absolute;
  bottom: -500px;
  left: 0;
  right: 0;
  z-index: 2;
}
.photo {
  transform: rotateY(180deg);
  margin: 0 auto;
  width: 340px;
  height: 370px;
  border-radius: 25px;
  object-fit: cover;
}
.photoPreview {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transform: rotateY(180deg);
  margin: 30px auto;
  object-fit: cover;
}
.flipImage {
  /* margin-top: 20%; */
  transform: rotateY(180deg);
}
.noImage {
  margin-top: 100px;
}
.continueOrRetake {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 15px 15px;
  z-index: 1;
}
.retakePhoto {
  float: left;
  margin-left: 40px;
  padding-bottom: 50px;
}
.proceed {
  float: right;
  margin-right: 40px;
  padding-bottom: 50px;
}
.icon {
  height: 30px;
}
.error {
  color: #ee7584;
}
.footer {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  margin: 0 auto;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
.footerRetake {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.fingerscan {
  padding: 60px 0;
}
.fingerscan .fingerscanIcon {
  height: 45px;
}
.acceptSignFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.main {
  position: relative;
  min-height: calc(100vh - 50px);
  padding-top: 70px;
}
.idPassportNumber {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.identity {
  color: #afafaf;
  font-size: 16px;
}

.retakePreview {
  width: 35vw;
  height: 36vw;
  max-width: 300px;
  max-height: 320px;
  border-radius: 15px;
  object-fit: cover;
  transform: rotateY(180deg);
  margin: 30px auto;
  padding: 0;
}
.retakeButtons {
  position: fixed;
  width: 100%;
  bottom: 0;
}
.CameraIcon {
  height: 26px;
}
.footerWhite {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
  z-index: 3;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerInner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 15px;
  background-color: #fff;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
/* @media (max-width: 991px) {
  .webcam {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
} */
/* @media (max-width: 767px) {
  .webcamContainer {
    width: 100vw;
    left: -12px;
  }
  .webcam {
    width: 100%;
    margin-left: 0;
  }
} */
.capturedPhotoContainer {
  padding-top: 10px;
}
@media (max-width: 991px) {
  .footer {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .webcam,
  .photo {
    height: 490px;
    width: 100%;
  }
  .faceOutlineImg {
    max-width: 430px;
    top: 235px;
  }
  .cameraBtns {
    bottom: -600px;
  }
  .continueOrRetake {
    border-top: 2px solid #e4e4e4;
    background-color: #fff;
  }
  .capturedPhotoContainer {
    padding-bottom: 180px;
  }
}

@media (max-width: 500px) {
  .webcam,
  .photo {
    height: 440px;
    width: 100%;
  }
  .faceOutlineImg {
    max-width: 390px;
    top: 215px;
  }
  .cameraBtns {
    bottom: -530px;
  }
}

@media (max-width: 400px) {
  .webcam,
  .photo {
    height: 310px;
    width: 100%;
  }
  .faceOutlineImg {
    max-width: 300px;
    top: 167px;
  }
  .cameraBtns {
    bottom: -450px;
  }
}

@media only screen and (max-width: 500px) and (max-height: 750px) {
  .photo {
    height: 310px;
    width: 100%;
  }
}

@media only screen and (max-width: 400px) and (max-height: 750px) {
  .photo {
    height: 310px;
    width: 100%;
  }
}

@media only screen and (max-width: 370px) and (max-height: 700px) {
  .photo {
    height: 230px;
    width: 100%;
  }
}
