.main {
  position: relative;
  padding-top: 80px;
  padding-bottom: 30px;
}
.docViewer {
  border: none;
  border-radius: 6px;
}
.signHereText {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgb(0 0 0 / 10%);
  border-radius: 100px;
  padding: 14px 10px;
  position: relative;
  right: -70px;
  width: 145px;
  z-index: 1;
  font-size: 16px;
  font-weight: 600;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.chevronLeftIcon {
  cursor: pointer;
  position: absolute;
  left: 15px;
  width: 30px;
}
.homeBtn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.btnSign {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  width: auto;
  align-items: center;
  cursor: pointer;

  background-color: #000;
  padding: 15px;
  z-index: 1;
}
.btnSign small {
  font-size: 10px;
}
.signBtn {
  z-index: 1;
  filter: brightness(0) invert(1);
  height: 25px;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
.plusCircle {
  height: 35px;
  cursor: pointer;
}
.addSignee {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 30px 15px;
  background-color: #fff;
}
.input {
  background: #f3f3f3;
  border-radius: 8px;
  border: none;
  padding: 15px;
  font-size: 14px;
  height: 150px;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.usersContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 0 10px 0;
  position: relative;
}
.user {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.initialsContainer {
  text-align: center;
  height: 32px;
  width: 32px;
  background-color: #000;
  border-radius: 50%;
  position: relative;
  text-transform: uppercase;
}
.center {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 14px;
}
.userName {
  width: calc(100% - 60px);
  margin-left: 10px;
  margin-bottom: 0;
  text-align: left;
}
