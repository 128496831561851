.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
.signedScribble {
  transform: scale(0.8);
  width: 20px;
}
