.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  text-align: center;
  padding: 15px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.homeBtn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
  z-index: 3;
  /* border-top-left-radius: 16px;
    border-top-right-radius: 16px; */
  border-top: 2px solid #e4e4e4;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
}

.customCheckInput input[type='checkbox'] {
  border-radius: 0% !important;
}

.chevronLeftIcon {
  cursor: pointer;
  position: absolute;
  left: 15px;
  width: 30px;
  top: 25px;
}

.additionalFieldContainer {
  border-bottom: 1px solid #eee;
  padding: 10px 5px 10px 0;
}
.additionalFieldContainer:last-child {
  border-bottom: none;
}
.additionalField {
  display: inline-flex;
  align-items: center;
  width: 100%;
  text-align: left;
}
.additionalFieldChildContainer {
  background: #f3f3f3;
  border-radius: 16px;
}
.additionalFieldChildContainer .additionalFieldContainer {
  border-bottom: 1px solid #fff;
  padding: 10px 5px 10px 0;
}

.additionalFieldContainerNoBorder {
  padding: 10px 5px 10px 0;
}
.fieldsScroller {
  height: calc(100vh - 325px);
  margin-left: -10px;
  width: calc(100% + 20px);
  padding-bottom: 20px;
}
.fieldsScrollerSelected {
  height: calc(100vh - 405px);
  margin-left: -10px;
  width: calc(100% + 20px);
  padding-bottom: 20px;
}
.fieldsScrollerDocs {
  height: calc(100vh - 345px);
  margin-left: -10px;
  width: calc(100% + 20px);
}
.item {
  padding: 0 10px;
}
.fieldsScrollerSmall {
  height: calc(100vh - 390px);
  margin-left: -10px;
  width: calc(100% + 20px);
}
.fieldsScrollerLarge {
  height: calc(100vh - 465px);
  margin-left: -10px;
  width: calc(100% + 20px);
}
@media (max-width: 767px) {
  .fieldsScroller {
    height: calc(100vh - 305px);
  }
  .fieldsScrollerSelected {
    height: calc(100vh - 390px);
  }
}
@media (max-width: 450px) {
  .fieldsScroller {
    height: calc(100vh - 330px);
  }
  .fieldsScrollerSelected {
    height: calc(100vh - 405px);
  }
}
