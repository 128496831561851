.fileImage {
  text-align: center;
}
.logoIconSplit {
  position: relative;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
}
.outerIcon {
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.innerIcon {
  position: absolute;
  height: 15px;
  bottom: 10px;
  right: 10px;
  left: unset;
}
