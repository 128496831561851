.main {
  padding-top: 60px;
  background: #fff;
}
.profilePic {
  height: 32px;
  width: 32px;
  margin: 0 auto;
  border-radius: 50%;
}
.transactions {
  border-top: 1px solid #f3f3f3;
  padding-top: 40px;
}
.openTransactions {
  background-color: #f3f3f3;
  padding-top: 50px;
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerInner {
  border-top: 2px solid #e4e4e4;
  padding: 30px 12px;
  background-color: #fff;
  z-index: 3;
}
.logoContainer {
  text-align: center;
  padding-bottom: 170px;
}
.transactionSpacer {
  height: 50vh;
}
.dotAnimation {
  position: absolute;
  left: 0;
  right: 0;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: #777;
  margin: 0 auto;
}
