.footer {
  text-align: center;
  padding: 15px;
  border-top: 2px solid #e4e4e4;
  width: calc(100% + 25px);
  margin-left: -13px;
  z-index: 3;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: inline-flex;
}
.logoLabel {
  font-weight: 700;
  font-size: 12px;
  color: #000000;
  opacity: 0.4;
  text-align: left;
  margin-bottom: 10px;
}
.idPhoto {
  width: 100%;
}
.IDPlaceholder {
  background: #f3f3f3;
  width: 100%;
  height: 100px;
  border-radius: 16px;
}
.edit {
  height: 18px;
  filter: brightness(0) invert(1);
}
.error {
  color: #ee7584;
}
.fullscreenSpinner {
  position: absolute;
  z-index: 3;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: inherit;
  height: 100vh;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
}

.emailError {
  color: #ee7584;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {
  .photo {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 400px) {
  .photo {
    width: calc(100vw - 60px);
    height: calc(100vw - 60px);
    margin: 0 auto 0;
  }
}
