.spinnerAnimation {
  position: relative;
}
.dotAnimation {
  /* position: absolute;
  left: 0;
  right: 0; */
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: #000;
  margin: 0 auto;
}
