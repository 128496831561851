.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 54px;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  /* position: fixed;
  height: 80px; */
}
