.progressVisualFull {
  display: flex;
  /* change the value if you want different thickness */
  height: 3px;
}

.progressVisualPart {
  /* Number of the seconds for complete animation */
  transition: width 1.5s;
}
