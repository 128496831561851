.main {
  padding-top: 100px;
  padding-bottom: 100px;
}
.photoBtns {
  margin-top: 55px;
}
.flipImage {
  /* margin-top: 20%; */
  transform: rotateY(180deg);
}
.photoPreview {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transform: rotateY(180deg);
  margin: 30px auto;
  object-fit: cover;
}
.acceptSignFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.footer {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
.footerWhite {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
  z-index: 3;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerInner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 15px;
  background-color: #fff;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.error {
  color: #ee7584;
  margin-top: 10px;
}

@media (max-width: 500px) {
  .photoBtns {
    margin-top: 40px;
    padding-bottom: 0;
  }
}
