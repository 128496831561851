.input {
  background: #fff;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}

.input:active,
.input:focus {
  background: #fff;
}

.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.labelLeft {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  margin-left: 15px;
}

@media (max-width: 767px) {
  .input {
    background: #f3f3f3;
  }

  .input:active,
  .input:focus {
    background: #f3f3f3;
  }
}
