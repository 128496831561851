.workspace {
  margin-bottom: 10px;
}
.transactionsPending {
  text-align: center;
  height: 40px;
  width: 40px;
  margin: 0 auto;
  background-color: #000;
  border-radius: 50%;
  position: relative;
}
.transactionsPendingAmount {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 24px;
}
.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
