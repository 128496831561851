.react-switch-checkbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.react-switch {
  cursor: pointer;
  width: 100%;
  border-radius: 100px;
  height: 54px;
  background: #e4e4e4;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch .react-switch-button {
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
  width: 50%;
  height: 46px;
  transition: 0.2s;
  background: #fff;
  box-shadow: none;
  border-radius: 100px;
}

.react-switch-checkbox:checked + .react-switch-button {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

.react-switch.disabled .react-switch-checkbox:checked + .react-switch-button {
  left: 4px;
  transform: unset;
}

.react-switch-labels {
  display: relative;
  z-index: 0;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
}

.react-switch-labels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  z-index: 1;
  font-weight: 700;
  font-size: 16px;
}
.react-switch-labels span:first-child {
  left: 0;
  opacity: 0.7;
}
.react-switch-labels span:last-child {
  right: 0;
  opacity: 0.7;
}
.small-switch {
  height: 38px;
  max-width: 150px;
}
.small-switch .react-switch-button {
  height: 30px;
}
