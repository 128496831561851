.deleteDoc {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.crossIcon {
  height: 10px;
}
.checkIcon {
  height: 6px;
  filter: invert(64%) sepia(0%) saturate(47%) hue-rotate(128deg) brightness(90%)
    contrast(87%);
  margin-right: 5px;
}
.uploadCloudIcon {
  filter: invert(64%) sepia(0%) saturate(47%) hue-rotate(128deg) brightness(90%)
    contrast(87%);
  opacity: 0.5;
  height: 30px;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}

.footerAnimate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  border-top: 2px solid #e4e4e4;
  padding: 30px 0;
  background-color: #fff;
}
.browseFilesContainer {
  text-align: center;
  border: 1px dotted #8e8e8e;
  width: 100%;
  padding: 28px;
  border-radius: 50%;
  width: 190px;
  height: 190px;
  margin: 30px auto 50px auto;
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}

.footerInner {
  border-top: 2px solid #e4e4e4;
  padding: 30px 12px;
  background-color: #fff;
}
.spacer {
  height: 140px;
  transition: 0.3s ease-in-out;
}
.progressSpacer {
  height: 18.5px;
}
.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
