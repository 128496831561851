.webcamContainer {
  position: relative;
  width: 100%;
  border-radius: 12px;
  max-height: 40vh;
}
.webcam {
  /* padding-bottom: calc(50% + 45px); */
  /* position: absolute; */
  left: 0;
  margin: 0 auto;
  object-fit: cover;
  width: 100%;
  border-radius: 12px;
  max-height: 40vh;
}
.photo {
  margin: 0 auto;
  height: auto;
  max-height: 40vh;
  width: 100%;
  padding: 0 10px;
  object-fit: cover;
}
.cameraBtns {
  padding-top: 30px;
  padding-bottom: 30px;
}
.retakePhoto {
  float: left;
  margin-left: 40px;
  padding-bottom: 50px;
}
.proceed {
  float: right;
  margin-right: 40px;
  padding-bottom: 50px;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.icon {
  height: 50px;
}
.webcamBorders {
  /* padding-bottom: 50%; */
  /* margin-left: 30px; */
  /* margin-top: 20px; */
  /* border-top: 5px solid transparent; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 90%;
  z-index: 3;
}
.webcamBorders div:nth-child(1):before {
  content: '';
  position: absolute;
  width: 35px;
  height: 5px;
  background: #fff;
  top: -5px;
  left: 0;
}
.webcamBorders div:nth-child(2):before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  background: #fff;
  top: -5px;
  left: 0;
}
.webcamBorders div:nth-child(3):before {
  content: '';
  position: absolute;
  width: 35px;
  height: 5px;
  background: #fff;
  top: unset;
  bottom: 0;
  left: 0;
}
.webcamBorders div:nth-child(4):before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  background: #fff;
  top: unset;
  bottom: 0;
  left: 0;
}
.webcamBorders div:nth-child(5):before {
  content: '';
  position: absolute;
  width: 35px;
  height: 5px;
  background: #fff;
  top: -5px;
  right: 0;
}
.webcamBorders div:nth-child(6):before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  background: #fff;
  top: -5px;
  right: 0;
}
.webcamBorders div:nth-child(7):before {
  content: '';
  position: absolute;
  width: 35px;
  height: 5px;
  background: #fff;
  top: unset;
  bottom: 0;
  right: 0;
}
.webcamBorders div:nth-child(8):before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  background: #fff;
  top: unset;
  bottom: 0;
  right: 0;
}
.continueOrRetake {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 15px 15px;
  z-index: 1;
}
@media (max-width: 600px) {
  .continueOrRetake {
    border-top: 2px solid #e4e4e4;
    background-color: #fff;
  }
}
