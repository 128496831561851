.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: alternativeAnimateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes alternativeAnimateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerInner {
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
  padding: 15px 12px 30px;
  background-color: #fff;
  z-index: 3;
  border-top: 2px solid #e4e4e4;
}
.DewlyLogo {
  padding-top: 50px;
  padding-bottom: 250px;
}
.footerAnimateOTP {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  z-index: 4;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
