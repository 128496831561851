.grayBlock {
  background-color: #f3f3f3;
  border-radius: 16px;
  padding: 30px;
}

.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 1;
}

.switchAccountsFooter {
  margin: 0;
  z-index: 2;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 0;
  background-color: #fff;
  min-height: 305px;
}

.accountsContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  text-align: left;
}

.spacer {
  height: 50px;
}

.accounts {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.switchBtn {
  width: 120px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  padding: 4px;
}

.cancel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .switchAccountsFooter {
    border-radius: 8px 8px 0 0;
  }
}

@media (max-height: 680px) {
  .footer {
    position: relative;
    padding: 10px 0 0 0;
    width: calc(100% + 60px);
    left: -30px;
  }
}
