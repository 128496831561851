.signButton {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin: 40px 0;
}
.transactionTitle {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  opacity: 0.4;
  margin-bottom: 0;
}
.scrollBox {
  height: calc(100vh - 380px) !important;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.cancelBtn {
  text-align: center;
  margin: 30px auto;
}
.logoContainer {
  text-align: center;
  padding: 50px 0 30px 0;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.photo {
  object-fit: contain;
}
.fullscreenSpinner {
  position: absolute;
  z-index: 3;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: inherit;
  height: 100vh;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
}
.centerContent {
  /* margin: 0;
  width: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: #fff;
  padding-bottom: 100px; */
  position: relative;
}
