.passwordInput {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}

.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}

.passwordReset {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.passwordToggle {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
}

.proceed {
  margin: 30px 0 0 0;
  padding-bottom: 50px;
}

.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}

@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.footerAnimateOTP {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  z-index: 4;
}

.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
  text-align: center;
}

.passwordChangeFooter {
  padding: 15px;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}

.error {
  color: #ee7584;
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
}

.confirmPass {
  color: #ee7584;
  font-size: 12px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: -20px;
}
.footerLogo,
.footerLogoNewPassword {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto 30px auto;
  text-align: center;
}

.centerContentNewPassword,
.centerContent {
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-height: 600px) {
  .footerLogoNewPassword {
    position: relative;
    padding-top: 50px;
  }
  .centerContentNewPassword {
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
    padding: 40px 0 0 0;
  }
}

@media (max-height: 800px) {
  .footerLogo {
    position: relative;
    padding-top: 50px;
  }
  .centerContent {
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
    padding: 40px 0 0 0;
  }
}
