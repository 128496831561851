.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  text-align: center;
  padding: 15px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.transactionInput {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px 50px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}
.error {
  color: #ee7584;
}
.cancelBtn {
  text-align: center;
  margin: 30px auto;
}
.homeBtn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
@media (max-height: 670px) {
  .cancelBtn {
    position: unset;
  }
}
