.otpInput {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.otpLink {
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.proceed {
  margin: 10% 0 0 0;
}
