.logoContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  z-index: 3;
}
.spacer {
  height: 35vh;
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
  padding: 0 15px;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}
.footerInner {
  padding: 30px 15px;
  background-color: #fff;
}

@media (max-height: 700px) {
  /* .logoContainer {
    position: relative;
  }
  .footer {
    position: relative;
  }
  .footerInner {
    width: 100%;
    margin: 0;
  } */
  .spacer {
    height: 40vh;
  }
}
@media (max-height: 500px) {
  .spacer {
    height: unset;
  }
}
