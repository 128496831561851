.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  text-align: center;
  padding: 15px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.emptyContainer {
  padding: 40px;
  border: 1px dotted #000;
  border-radius: 16px;
  opacity: 0.5;
  text-align: center;
  height: 100px;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
  z-index: 3;
  /* border-top-left-radius: 16px;
  border-top-right-radius: 16px; */
  border-top: 2px solid #e4e4e4;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
  border-top: 2px solid #e4e4e4;
}
.signeeContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
}
.signee {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 8px;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
.homeBtn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.center {
  color: #8e8e8e;
  margin-left: 5px;
  font-weight: 800;
  font-size: 10px;
}

.chevronLeftIcon {
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 25px;
  width: 30px;
}
.addUserIcon {
  padding: 30px 0;
}
