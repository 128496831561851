.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 29.5px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: #fff;
  z-index: 9999;
}
.goBack {
  position: absolute;
  left: 15px;
  cursor: pointer;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
.form {
  padding-top: 100px;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #e4e4e4;
  padding: 15px 10px;
  background-color: #fff;
  z-index: 3;
}
.error {
  color: #ee7584;
}
