.transactionTitle {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  opacity: 0.4;
}

.btnFooter,
.footer {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 50px;
}

.dotAnimation {
  left: 0;
  right: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #000;
  margin: 0 auto;
}

.spacer {
  height: 75px;
}

.signedText {
  position: relative;
  top: -10px;
}

.signedAnimationContainer {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.scribbleIcon {
  height: 30px;
}

.error {
  color: #ee7584;
  margin-top: 25%;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  text-align: center;
}
