.edit {
  height: 25px;
  filter: brightness(0) invert(1);
}
.IDPlaceholder {
  background: #f3f3f3;
  width: 100%;
  height: 180px;
  border-radius: 16px;
}
.placeholder {
  background: #fff;
  width: 100%;
  height: 120px;
  border-radius: 16px;
  border: 1px dotted #8e8e8e;
  position: relative;
}
.label {
  font-weight: 700;
  font-size: 12px;
  color: #000000;
  opacity: 0.4;
  text-align: left;
  margin-bottom: 10px;
}
.idPhoto {
  width: 100%;
}
