.spacer {
  height: 80px;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.footer {
  padding-bottom: 50px;
  padding-top: 50px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.error {
  color: #ee7584;
}
.centerContent {
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-height: 991px) {
  .spacer {
    height: 60px;
  }
}
@media (max-height: 767px) {
  .spacer {
    height: 40px;
  }
}
@media (max-height: 650px) {
  .spacer {
    height: 30px;
  }
}
@media (max-height: 600px) {
  .centerContent {
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
    padding: 40px 10px 0 10px;
  }
}
