.notifications-container .notificationIndicator {
  position: fixed;
  right: 13px;
  top: 15px;
  z-index: 2;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #9747ff;
}

.notifications-container .bm-burger-button {
  position: fixed;
  width: 16px;
  height: 16px;
  right: 15px;
  left: unset;
  top: 15px;
}

.notifications-container .bm-cross-button {
  position: fixed !important;
  right: 15px !important;
  top: 15px !important;
  width: 12px !important;
  height: 12px !important;
}

.notifications-container .bm-menu-wrap {
  position: fixed;
  height: 100%;
  background: #fff;
}

.notifications-container .bm-menu::-webkit-scrollbar {
  display: none;
}

.notifications-container .bm-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.notifications-container .bm-menu {
  background: #fff;
  padding: 0;
  font-size: 1.15em;
}

.headerText {
  position: fixed;
  width: 16px;
  height: 16px;
  left: 15px;
  top: 4px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
}

.notifications-container .bm-item-list {
  padding: 0;
  text-align: left;
}

.notifications-container .notifications-footer {
  text-align: center;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-top: 30px;
}

.time {
  display: inline-flex;
  color: #8e8e8e;
}

.time .dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #9747ff;
}

.scrollBox {
  height: calc(100vh - 245px) !important;
}

.readNotifications * {
  opacity: 0.8;
}

.switch input {
  padding: 0 60px;
  margin: 30px 0;
  width: calc(100% - 60px) !important;
  height: 54px;
  margin-left: 30px !important;
}

#react-burger-cross-btn {
  height: 30px !important;
  width: 30px !important;
}

.content {
  display: block;
  position: fixed;
  width: 100%;
  height: calc(100% - 41px);
  background: #fff;
  overflow-y: hidden;
  top: 1px;
  margin-top: 40px;
}
