.dotAnimation {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 22px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto;
}
