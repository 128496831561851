.userInitials {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 auto;
  background: #000;
  color: #fff;
  font-weight: 700;
  position: relative;
  text-align: center;
}
.centerInitials {
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
  line-height: 1;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
  border-top: 2px solid #e4e4e4;
}
.footerInner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #e4e4e4;
  padding: 15px 10px;
  background-color: #fff;
  z-index: 3;
}
.form {
  padding-bottom: 100px;
}
.error {
  color: #ee7584;
}

.edit {
  height: 25px;
  filter: brightness(0) invert(1);
}
.IDPlaceholder {
  background: #f3f3f3;
  width: 100%;
  height: 180px;
  border-radius: 16px;
}
.placeholder {
  background: #fff;
  width: 100%;
  height: 120px;
  border-radius: 16px;
  border: 1px dotted #8e8e8e;
  position: relative;
}
.logoLabel {
  font-weight: 700;
  font-size: 12px;
  color: #000000;
  opacity: 0.4;
  text-align: left;
  margin-bottom: 10px;
}
.idPhoto {
  width: 100%;
}
