.main {
  position: relative;
  min-height: calc(100vh - 50px);
  padding-top: 70px;
}
.idPassportNumber {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.icon {
  height: 50px;
}
.error {
  color: #ee7584;
}
