.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.iconInput {
  position: absolute;
  bottom: 19px;
  right: 35px;
  opacity: 0.4;
  height: 15px;
}
.footerInner {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 2px solid #e4e4e4;
  position: fixed;
  bottom: 0;
}
.error {
  color: #ff0000;
  position: absolute;
}
