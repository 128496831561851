.photo {
  display: inline-block;
  width: 250px;
  height: 250px;
  margin: 15px auto 0;
  border-radius: 50%;
  object-fit: cover;
}
.editProfilePhoto {
  position: relative;
  top: -1px;
  height: 15px;
}
.viewFileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
.footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 30px 0;
  border-top: 2px solid #e4e4e4;
  background: #fff;
  width: inherit;
  margin-left: -12px;
  z-index: 2;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.footerAnimateOTP {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  z-index: 4;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
  text-align: center;
}
.passwordChangeFooter {
  padding: 15px;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.verifyStatus {
  margin: 50px 0;
}
.verifyStatus span {
  font-size: 12px;
  font-weight: 700;
  background: #f3f3f3;
  padding: 8px 10px;
  border-radius: 100px;
  color: grey;
  letter-spacing: 0.7px;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.error {
  color: #ee7584;
  margin-bottom: 5px;
}
@media (max-width: 1300px) {
  .photo {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 700px) {
  .photo {
    width: 25vw;
    height: 25vw;
    margin: 0 auto 0;
  }
}

/* @media (max-height: 700px) {
  .footer {
    position: relative;
    width: calc(100% + 24px);
  }
} */
