.main {
  position: relative;
  min-height: calc(100vh - 50px);
  padding-top: 70px;
}
.idPassportNumber {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.icon {
  height: 50px;
}
.error {
  color: #ee7584;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
}
.dd {
  /* background-color: transparent;
  background-position: bottom; */
  margin: 0 auto;
  text-align: center;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
}
.centerSelect {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
}
.countrySelected {
  position: relative;
  bottom: -24px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.selectDropdown {
  color: transparent;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 30px;
  border: none;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  /* color: #000; */
  background: transparent;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.selectDropdown:focus,
.selectDropdown:hover,
.selectDropdown:active,
.selectDropdown:focus-visible {
  outline: none;
  cursor: pointer;
}
.selectDropdown option {
  color: #000;
}
.arrowDown {
  margin-top: 35px;
}
