.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  margin: 0 auto;
  padding: 24px 16px;
  background-color: #fff;
}
.logoContainer {
  padding: 0 0 64px 0;
}

.content {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 108px;
}
