@font-face {
  font-family: 'manrope-bold';
  src: local('ManropeBold'),
    url('./fonts/Manrope-Bold.woff2') format('truetype');
}
@font-face {
  font-family: 'manrope-extra-bold';
  src: local('ManropeExtraBold'),
    url('./fonts/Manrope-ExtraBold.woff2') format('truetype');
}
@font-face {
  font-family: 'manrope-extra-light';
  font-weight: bold;
  src: local('ManropeExtraLight'),
    url('./fonts/Manrope-ExtraLight.woff2') format('truetype');
}
@font-face {
  font-family: 'manrope-light';
  src: local('ManropeLight'),
    url('./fonts/Manrope-Light.woff2') format('truetype');
}
@font-face {
  font-family: 'manrope-medium';
  src: local('ManropeMedium'),
    url('./fonts/Manrope-Medium.woff2') format('truetype');
}
@font-face {
  font-family: 'manrope-regular';
  src: local('ManropeRegular'),
    url('./fonts/Manrope-Regular.woff2') format('truetype');
}
@font-face {
  font-family: 'manrope-semi-bold';
  src: local('ManropeSemiBold'),
    url('./fonts/Manrope-SemiBold.woff2') format('truetype');
}
@font-face {
  font-family: 'scribble-font';
  src: local('ScribbleFont'),
    url('./fonts/scribble-font.otf') format('opentype');
}

* {
  box-sizing: border-box;
}
html {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Manrope-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background: #f3f3f3;
  height: 100%;
  width: 100%;
  /* position: fixed;
  overflow-y: scroll;
  overflow-x: hidden; */
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
#outer-container {
  min-height: 100vh;
  max-height: -webkit-fill-available;
}
.no-horizontal-scroll {
  overflow-x: hidden;
}
.min-height-full-page {
  min-height: 100vh;
  max-height: -webkit-fill-available;
}
.divider {
  width: 100%;
  height: 1px;
  background: #e4e4e4;
}
.border-bottom {
  border-bottom: #e4e4e4;
}
.align-items-flex-start {
  align-items: flex-start !important;
}
.modal {
  z-index: 1051 !important;
}
.modal-fullscreen .modal-content {
  width: 100vw;
  max-height: unset;
}
.modal .columns {
  position: fixed;
  width: calc(100% - 40px);
  bottom: 75px;
  left: 20px;
}
.btn {
  position: relative;
  border-width: 2px;
  padding: 5px 15px 5px 15px;
  line-height: 0;
  font-size: 16px;
  cursor: pointer;
  min-height: 54px;
}
a.btn {
  line-height: 1;
}
.btn-sm {
  line-height: 1;
  min-height: unset;
  padding: 5px 10px;
}
.btn-block {
  padding: 18px;
  width: 100%;
}
.whiteButton {
  background-color: #ffffff !important;
}
.whiteButtonWithBorder {
  background-color: #ffffff !important;
  border: solid black 2px;
}
.whiteButtonWithBorder:hover {
  border: black 2px solid !important;
}
.blackButton {
  border: solid black 2px;
}
.blackButton:hover {
  border: solid black 2px;
}
.blackButton:focus {
  border: solid black 2px;
}
.btn-md-rounded {
  height: 52px;
  width: 52px;
}
.btn-lg-rounded {
  height: 65px;
  width: 65px;
}
.btn-rounded {
  border-radius: 100px;
}
.btn-shadow {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn-white {
  background-color: #fff;
}
.btn-50 {
  height: 50px;
  width: 50px;
  min-height: 50px;
}
.shareIcon a {
  margin-bottom: 0;
}
.shareIcon svg {
  height: 1.2em !important;
  width: 1.2em !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.h-54 {
  height: 54px;
}
.h-70 {
  height: 70px;
}
a,
a:hover {
  text-decoration: none;
  color: inherit;
}
.center-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cardWhite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px 20px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05),
    8px 16px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.cardGray {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  border: none;
  background: #f3f3f3;
  border-radius: 8px;
}
.bg-light {
  background-color: #f3f3f3 !important;
}
.rounded {
  border-radius: 8px !important;
}
.text-left {
  text-align: left;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.top-spacer {
  padding-top: 80px;
}
.toggle-switch-block {
  padding: 0;
}
.toggle-switch-block input {
  width: 100% !important;
  margin-left: 0 !important;
  height: 54px;
}
.form-switch .form-check-input:checked {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.form-switch .form-check-input:focus {
  box-shadow: none;
  border-color: #f3f3f3;
}
.form-check-input {
  border: 2px solid #000000;
  border-radius: 50% !important;
  height: 1.5rem;
  width: 1.5rem;
}
.form-check-input:checked[type='checkbox'] {
  background-image: none !important;
}
.form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
}
.form-check-input:focus {
  border-color: #000000;
  box-shadow: none;
}
.form-check label {
  margin-left: 10px;
  margin-top: 3px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
}

input {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.pointer {
  cursor: pointer;
}
/* #page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
} */

/* ICON CSS */
.icon {
  z-index: 2;
}
.sm-icon .icon {
  height: 10px;
}
.md-icon .icon {
  height: 14px;
}
.lg-icon .icon {
  height: 18px;
}

/* FORM CSS */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control:focus {
  border-color: transparent;
  box-shadow: none;
  background-color: #f3f3f3;
}
input:focus::placeholder {
  color: transparent;
}
::placeholder {
  color: #afafaf !important;
  font-size: 12px !important;
}
.form-control.valid-input:focus,
input.valid-input {
  background: #eef9ef;
}
.form-control.invalid-input:focus,
input.invalid-input {
  background: #fff0f0;
}
.form-select {
  height: 54px;
  background-color: #f3f3f3;
  border-radius: 100px;
  border: none;
}
.form-select:focus {
  border-color: transparent;
  box-shadow: none;
}
/* PDF VIEWER CSS OVERRIDE */
.rpv-core__doc-error {
  border-radius: 6px;
}
.pulse-animate {
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
button:disabled.pulse-animate {
  animation: none;
}
.no-box-shadow,
.no-box-shadow:focus {
  box-shadow: none;
}
.figure-caption {
  color: #000;
  font-size: 14px;
}
.v-align-top {
  vertical-align: top;
}
.white-space-nowrap {
  white-space: nowrap;
}
/* PDF STYLES OVERRIDE */
.pdf-viewer .rpv-core__inner-page {
  background: transparent;
  background-color: transparent !important;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.p-fixed {
  position: fixed;
}
.w-full {
  width: 100%;
}
.w-300 {
  width: 300px;
}
.p0 {
  padding: 0;
}
.m0 {
  margin: 0;
}
.ml-0 {
  margin-left: 0 !important;
}
.-m-r-15 {
  margin-right: -15px;
}
.-m-l-15 {
  margin-left: -15px;
}
.m-b-0 {
  margin-bottom: 0;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-23 {
  margin-top: 23px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-b-80 {
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .xs-mb-0 {
    margin-bottom: 0 !important;
  }
  .xs-mb-3 {
    margin-bottom: 1rem !important;
  }
}
.p-l-0 {
  padding-left: 0;
}
.p-r-0 {
  padding-right: 0;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-t-23 {
  padding-top: 23px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-b-85 {
  padding-bottom: 85px;
}
.p-b-185 {
  padding-bottom: 185px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-t-100 {
  padding-top: 100px;
}
.p-t-120 {
  padding-top: 120px;
}
.p-t-200 {
  padding-top: 200px;
}
.p-b-24 {
  padding-bottom: 24px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-b-80 {
  padding-bottom: 80px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-b-120 {
  padding-bottom: 120px;
}
.p-b-150 {
  padding-bottom: 150px;
}
.p-b-160 {
  padding-bottom: 160px;
}
.p-b-170 {
  padding-bottom: 170px;
}
.p-b-180 {
  padding-bottom: 180px;
}
.p-b-200 {
  padding-bottom: 200px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}
.p4 {
  padding: 1.5rem;
}
.p-b-250 {
  padding-bottom: 250px;
}
.caps {
  text-transform: lowercase;
  display: inline-block;
}
.caps::first-letter {
  text-transform: capitalize;
}
.scribble-text {
  font-family: 'scribble-font' !important;
}
.fw-200 {
  font-family: 'manrope-extra-light';
}
.fw-300 {
  font-family: 'manrope-light';
}
.fw-400 {
  font-family: 'manrope-regular';
}
.fw-500 {
  font-family: 'manrope-medium';
}
.fw-600 {
  font-family: 'manrope-semi-bold';
}
.fw-700 {
  font-family: 'manrope-bold';
}
.fw-800 {
  font-family: 'manrope-extra-bold';
}
.fs-24 {
  font-size: 24px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.h-90 {
  height: 90px;
}
.h-60 {
  height: 60px;
}
.h-24 {
  height: 24px;
}
.h-26 {
  height: 26px;
}
.h-8 {
  height: 8px;
}
.w-60 {
  width: 60px;
}
.top-less-20 {
  top: -20px;
}
.top-less-10 {
  top: -10px;
}
.h-30 {
  height: 30px;
}
.w-30 {
  width: 30px;
}
.color-lightgray {
  color: #afafaf;
}
.color-gray {
  color: #aaa;
}
.color-black {
  color: #000;
}
.color-red {
  color: #ee7584;
}
.color-green {
  color: #40d2a7;
}
.bg-red {
  background-color: #ee7584 !important;
}
.bg-green {
  background-color: #40d2a7 !important;
}
.bg-lightred {
  background-color: #fff0f0 !important;
}
.bg-lightgreen {
  background-color: #eef9ef !important;
}
.bg-lightgray {
  background-color: #f3f3f3;
}
.line-height-1 {
  line-height: 1;
}
.all-uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.phoneInput {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
}

.phoneInput input {
  background-color: transparent;
  border: 0px;
  outline: transparent !important;
  box-shadow: none;
}

input[type='email'] {
  text-transform: lowercase;
}
.react-tel-input .form-control {
  background: transparent !important;
  border: none !important;
  height: unset !important;
  padding: 0 0 0 45px !important;
  width: inherit !important;
  width: -webkit-fill-available !important;
}
.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
  border: none !important;
}
.inputIcon {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  height: 25px;
  transform: translateY(-50%);
  background: darkgrey;
  border-radius: 50%;
  padding: 5px;
  opacity: 0.4;
  cursor: pointer;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-0 {
  opacity: 0;
}
.noAcknowledgementCard {
  min-height: 42px;
}
.border-rounded-top {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.transform-90 {
  transform: rotate(90deg);
}
.zindex-10 {
  z-index: 10;
}

.fullscreen-confirm-popup-container {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 120vh;
  background-color: rgb(0 0 0 / 77%);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

.confirm-popup-content {
  max-width: 85%;
  width: 500px;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 20vh;
}
.popup-content {
  background-color: #ffffff;
}
.acknowledgement-details {
  text-align: left;
  padding-left: 2rem;
}

.popup-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  left: 0;
}

.styles_header__4tBVH {
  z-index: 999 !important;
}

.acknowledgement-text p {
  white-space: pre-line;
}

.acknowledgement-list p {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.overlay {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 90;
  background-color: rgb(0 0 0 / 37%);
  top: 0;
}

.form-section-heading {
  display: block;
  text-align: center;
  margin: 3rem 0;
  margin-bottom: 2rem;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 16px;
  color: #afafaf;
}

.accept-acknowledgement-popup-content.absolute-popup-content {
  position: fixed;
  left: 0;
  /* width: 100%; */
  bottom: 0;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  /* margin: 0 !important; */
  border-radius: 10px 10px 0px 0px;
}

.group-heading {
  margin-top: 4.5rem;
  margin-bottom: 1.5rem;
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  color: #6f6e70;
  text-transform: capitalize;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
}
.border-top-15-white {
  border-top: 15px solid #fff;
}

#add-acknowledgements .min-height-full-page {
  padding-bottom: 6rem;
  min-height: calc(100vh - 120px);
}

.acknowledgements-edit {
  z-index: 100 !important;
  padding: 0px !important;
  background-color: transparent !important;
  border-top: 2px solid transparent !important;
}

.acknowledgements-edit .form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.acknowledgements-edit .form-check-inline input {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.app-width {
  max-width: 800px;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 100% !important;
}

.acknowledgements-edit .form-check-label {
  font-family: 'manrope-bold';
  cursor: pointer;
}

.accept-acknowledgement-popup-content {
  z-index: 99;
  display: none;
}
.accept-acknowledgement-popup-content:nth-child(1) {
  z-index: 100;
  display: block;
}

.applicable-to .loader {
  position: absolute;
  right: 6%;
  top: 7%;
}

.slide-up {
  animation: animateUp 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
}

.slide-up.hide-div {
  animation: animateUp 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.max-width {
  max-width: 500px;
  margin: 0 auto;
}
.badge-info {
  background-color: #000000;
}

.btn-no-hover-change:hover,
.btn-no-hover-change:active,
.btn-no-hover-change:focus {
  background-color: transparent !important;
  color: #000 !important;
}
button.btn-dark {
  background-color: #000;
  border-color: #000;
}
/* Ripple effect */
button.btn-dark {
  background-position: center;
  transition: background 0.8s;
}
button.btn-dark:hover {
  background: #000 radial-gradient(circle, transparent 1%, #000 1%)
    center/15000%;
  border-color: #000;
}
button.btn-dark:active {
  background-size: 100%;
  transition: background 0s;
  border-color: #000 !important;
}

@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes animateDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fileViewerZoomButton {
  display: none;
  box-shadow: 0px 0px 8px 1px #00000040;
}
.fileViewerZoomButton span,
.fileViewerZoomButton .rpv-core__popover-body {
  font-family: 'manrope-extra-bold';
}
.fileViewerZoomButton .rpv-core__minimal-button:hover {
  background-color: transparent;
}
.fileViewerZoomButton .rpv-zoom__popover-target-arrow {
  position: relative;
  display: inline-block;
  background: transparent;
  border-radius: 30px;
  height: 11px !important;
  width: 11px !important;
  border: 3px solid #000;
}
.fileViewerZoomButton .rpv-zoom__popover-target-arrow:after {
  content: '';
  height: 2px;
  width: 4px;
  background: #000;
  position: absolute;
  top: 8px;
  left: 6px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.fileViewerZoomButton .rpv-zoom__popover-target-arrow {
  border-color: #000 !important;
  border-style: solid !important;
  border-width: 3px !important;
}
.fileViewerZoomButton .rpv-core__popover-body {
  left: -57px !important;
  top: 45px !important;
  border: none;
  box-shadow: 0px 0px 8px 1px #00000040;
}
.fileViewerZoomButton .rpv-core__menu-divider {
  border-bottom: 1px solid #00000040;
}
.fileViewerZoomButton .rpv-core__arrow--bc {
  left: unset !important;
  right: 25px;
  border-bottom: 1px solid #00000040 !important;
  border-left-color: #00000040 !important;
  border-right: 1px solid #00000040 !important;
  border-top-color: #00000040 !important;
}
.fileViewerZoomButton .rpv-core__menu-item-label--ltr {
  padding-right: 5px !important;
}

/* RESONSIVE */
@media (max-width: 991px) {
  .fileViewerZoomButton {
    display: block;
  }
}
@media (max-width: 767px) {
  .mobile-order-1 {
    order: 1;
  }
  body,
  html {
    background-color: #fff;
    background: #fff;
  }
}
@media (max-height: 700px) {
  .max-height-unset-700 {
    min-height: unset;
    max-height: unset;
  }
}
@media (max-width: 460px) {
  .hide-460 {
    display: none;
  }
}
