.signeeContainer,
.workspaceContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 5px 15px 0;
}
.signeeContainer:last-child,
.workspaceContainer:last-child {
  border-bottom: none;
}
.signee,
.workspace {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.editBtn {
  height: 15px;
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
}
.footerInner {
  border-top: 2px solid #e4e4e4;
  padding: 30px 12px;
  background-color: #fff;
  z-index: 3;
}
.logoContainer {
  text-align: center;
  padding-bottom: 150px;
}
.scrollBox {
  height: calc(100vh - 150px) !important;
  padding-top: 10px;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
.plusCircle {
  height: 35px;
  cursor: pointer;
}
.newWorkspaceFooter {
  border-top: 2px solid #e4e4e4;
  padding: 30px 12px;
  background-color: #fff;
}
.footerInner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #e4e4e4;
  padding: 15px;
  background-color: #fff;
  z-index: 1;
}
.input {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  font-size: 16px;
  text-align: center;
}
@media (max-width: 767px) {
  .newWorkspaceFooter {
    border-radius: 8px 8px 0 0;
    border-top: none;
  }
}
