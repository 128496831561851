.spacerXL {
  height: 120px;
}
.spacer {
  height: 80px;
}
.usersContainer {
  border-bottom: 1px solid #eee;
  padding: 15px 0 10px 0;
  position: relative;
}
.user {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.verifiedLabel {
  font-weight: 700;
  font-size: 10px;
  width: 100px;
  background: #f3f3f3;
  padding: 5px 10px;
  border-radius: 100px;
  text-align: center;
  color: #8e8e8e;
}
.share {
  position: absolute;
  right: 0;
}
.initialsContainer {
  text-align: center;
  height: 32px;
  width: 32px;
  background-color: #000;
  border-radius: 50%;
  position: relative;
  text-transform: uppercase;
}
.center {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 14px;
}
.userName {
  width: calc(100% - 130px);
  margin-left: 10px;
  margin-bottom: 0;
}
.footer {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}
.everyoneSigned {
  text-align: center;
  height: 54px;
  width: 100%;
  border-radius: 100px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: #8e8e8e;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
@keyframes animateUp {
  0% {
    transform: translateY(160px);
  }
  100% {
    transform: translateY(0);
  }
}
.userInfo {
  padding: 30px 12px;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.shareFooter {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
.shareButtons {
  border-top: 2px solid #e4e4e4;
  padding: 15px 12px;
  background-color: #fff;
  border-top: 2px solid #e4e4e4;
}
.footerInner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #e4e4e4;
  padding: 15px;
  background-color: #fff;
  z-index: 1;
}
.closeArrow {
  cursor: pointer;
  padding: 10px;
}
.photo {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 0 0 20px 0;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .userInfo {
    border-radius: 8px 8px 0 0;
    border-top: none;
  }
}
