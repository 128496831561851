.slide {
  position: relative;
  width: 100%;
  height: 40vh;
}
.show {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade 1.5s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.noShow {
  display: none;
}
.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #fff;
  display: inline-flex;
	border: 1px solid #000;
	border-radius: 50%;
  transition: background-color 0.6s ease;
}
.activeDot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #000;
  display: inline-flex;
	border: 1px solid #000;
	border-radius: 50%;
  transition: background-color 0.6s ease;
  opacity: 1;
}